<template>
<div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="add()">新增</Button>
    </Col>
  </Row>
  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
        <template slot="action" slot-scope="{row}">
          <Button type="error" @click="del(row.tag_id)">删除</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>

  <Modal v-model="modal" @on-ok="addTag()" >
    <div style="margin-top: 10px">
      名称：
      <Input v-model="form.tag_name" style="width:80%"/>
    </div>
  </Modal>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "Tag",
  data: function(){
    return {
      form: {
        tag_name: ''
      },
      modal: false,
      searchKey: {
        page: 1,
        pagesize: 30
      },
      list: [],
      total: 0,
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '名称', key: 'tag_name'},
        {title: '操作', slot: 'action', width: 120, align: 'center'}
      ],
    }
  },
  methods: {
    getList: function(){
      this.$http.get(api.tagList).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确实要删除这一项吗？</p>',
        onOk: () => {
          this.$http.delete(api.delTag, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.$Message.success('删除成功');
              this.getList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    add: function(){
      this.modal = true;
    },
    addTag: function(){
      this.$http.post(api.addTag, this.form).then(response => {
        if(response.data.success) {
          this.$Message.success('添加成功');
          this.getList();
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>